import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/layout'),
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home')
      },
      {
        path: 'products',
        component: () => import('@/views/products')
      },
      {
        path: 'about',
        component: () => import('@/views/about')
      },
      {
        path: 'contact',
        component: () => import('@/views/contact')
      },
      {
        path: 'datas',
        component: () => import('@/views/datas')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
