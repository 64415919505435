<template>
  <div>
    <el-backtop></el-backtop>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="sass" scoped></style>
